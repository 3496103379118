@import '../../styles/styles';

.property {
  flex: 0 0 343px;
  margin-right: 24px;
  text-decoration: none;

  @include mediaQuery(max, 1272px) {
    flex: 0 0 325px;
    margin-right: 25px;
  }

  @include mediaQuery(max, $mdDesktop) {
    flex: 0 0 285px;
    margin-right: 20px;

    &:last-child {
      position: relative;

      &:after {
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        right: -2rem;
        top: 0;
        width: 2rem;
      }
    }
  }
}

.image {
  @extend .image;
  border-radius: 4px;
  margin: 0 0 0.75rem 0;
  width: 100%;
  height: 175px;
  overflow: hidden;
}

.propertyLabel {
  background-color: $dark-cerulean;
  border-radius: 3px;
  color: $white;
  cursor: pointer;
  display: inline-block;
  font-family: $font-secondary;
  font-size: 12px;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 12px;
  margin-bottom: 4px;
  padding: 5px 8px 5px;
  text-align: center;
  text-transform: uppercase;
}

.geoLocation {
  color: $blue-extra-dark;
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;

  @include mediaQuery(max, $mdDesktop) {
    font-size: 0.9rem;
  }
}

.pricing {
  color: $casal;
  display: block;
  font-size: 11px;
  font-weight: 500;
  margin-top: 4px;
}
