@import '../../styles/styles';

.destinationSection {
  @include mediaQuery(max, 1023px) {
    padding: 2rem 0;
  }

  @include mediaQuery(max, $mobile) {
    padding: 1rem 0;
  }
}

.content {
  margin: 0 auto;
  max-width: $maxContentWidth;

  @include mediaQuery(max, $mdDesktop) {
    max-width: 915px;
  }

  @include mediaQuery(max, 1023px) {
    padding: 3rem 0 1rem;
  }
}

.destinationTitle {
  @include mediaQuery(max, 1085px) {
    margin-left: 3rem;
  }

  @include mediaQuery(max, $mobile) {
    margin: 0 1rem;
  }
}

.destinationsSubtitle {
  max-width: 85%;
}

.destinationCarousel {
  max-width: $maxContentWidth;

  @include mediaQuery(max, 1255px) {
    margin-left: 0;
    max-width: 876px;
  }

  @include mediaQuery(max, 1023px) {
    max-width: 100%;
  }
}

.destinationCarouselTrack {
  height: 240px;
  max-width: 100%;
}

.carouselArrow {
  margin-top: 0;
}

.destinationRightArrow {
  right: -35px;
}
.destinationItem {
  border-radius: 4px;
  flex: 0 0 204px;
  margin-right: 15px;
  overflow: hidden;
  position: relative;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    .destinationItemImage {
      transform: scale(1.1);
    }
  }
  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 50%;
    opacity: 0.3;
    background-image: linear-gradient(0deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    pointer-events: none;
  }
}

.destinationItemImage {
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
  height: 100%;
  transition: all 1000ms;
  width: 100%;
}

.destinationItemMask {
  background-color: rgba(0, 0, 0, 0.14);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.destinationLocation {
  bottom: 1rem;
  color: $white;
  font-weight: 600;
  position: absolute;
  left: 0.9375rem;
  text-decoration: none;
  z-index: 2;
}

.responsiveLayout {
  > div {
    width: 100%;
    max-width: 100%;
  }
  .destinationTitle {
    @include mediaQuery(max, 1085px) {
      margin-left: 0;
    }

    @include mediaQuery(max, $mobile) {
      margin: 0;
    }
  }
  .destinationCarouselTrack {
    padding-left: 0;
  }

  .destinationCarousel {
    @include mediaQuery(max, 1255px) {
      max-width: 100%;
    }
  }
}
