@import '../../styles/styles';

.propertyCarousel {
  @include mediaQuery(max, 1276px) {
    max-width: 1050px;
    margin: 0;
  }
}

.propertyCarouselArrow {
  top: 25%;
  transform: translateY(-50%);
}
