@import '../../styles/styles';

.desktopSearchBox {
  width: 100%;
}

.searchBox {
  position: relative;

  @include searchPageOnly {
    @include searchFilterCopy;

    &:not(.focused) {
      height: 40px;
      @include verticalAlign;
    }
  }

  input {
    min-width: 18rem;
    @include searchPageOnly {
      &::placeholder {
        @include searchFilterCopy;
      }
    }

    @include mediaQuery(max, $tablet) {
      min-width: 0;
    }

    @include mediaQuery(max, $mobile) {
      width: 100%;
    }
  }

  @include mediaQuery(max, $tablet) {
    &.focused {
      background-color: $white;
      bottom: 0;
      left: 0;
      margin-left: 0;
      position: fixed;
      right: 0;
      top: 0;
      z-index: 11;

      .searchInput {
        border: none;
        border-bottom: 1px solid $inputBorder;
        border-radius: 0;
      }

      .searchIcon {
        top: 4.5rem;

        @include searchPageOnly {
          top: 2.5rem;
          margin-left: 0;
          transform: translateY(50%);
        }
      }

      .suggestions {
        box-shadow: none;
        margin-left: 0 !important;
        right: 0;
        top: 6.4rem;

        div {
          margin-left: 0 !important;
        }
      }
    }
  }
}

.searchInput {
  @include Ellipsis;

  border: 1px solid $inputBorder;
  display: block;
  color: $casal;
  font-size: 16px;
  height: 50px;
  padding: 0.75rem 2.5rem;
  position: relative;
  transition: none;

  @include searchPageOnly {
    @include searchFilterCopy;
    height: 40px;
    padding: 0.75rem 2.5rem 0.75rem 0rem;
    padding-left: 33px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-clamp: 1;
    -webkit-line-clamp: 1;
  }

  @include mediaQuery(min, $tablet) {
    font-size: 1rem;
    min-width: 400px;
    padding: 1rem 2.5rem;
  }

  @include mediaQuery(max, $tablet) {
    min-width: 100% !important;
  }

  &.hasValue {
    font-weight: 600;
  }
}

.searchIcon {
  left: 0.3rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.searchIconGrey {
  left: 0.525rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  height: 1.6rem;
  width: 1.6rem;
}

.clearIcon {
  cursor: pointer;
  padding: 1rem;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 43px;

  @include mediaQuery(max, $tablet) {
    top: 3.25rem;
    transform: translateY(0);
    height: 47px;

    @include searchPageOnly {
      top: 3rem;
      right: -5px;
    }
  }
}

.suggestions {
  background-color: $white;
  box-shadow: $standardBoxShadow;
  border-radius: 4px;
  border-top: none;
  display: none;
  left: 0;
  position: absolute;
  top: 100%;
  z-index: 9;
  margin-top: 5px;
  width: 100%;

  &.hasSuggestions {
    display: flex;
    flex-direction: column;
  }
}

.suggestionIcon {
  display: flex;
  flex-direction: column;
  align-self: start;
  margin-top: -2px;
  margin-right: 10px;

  // $smokyMountains at 0.5 opacity
  fill: rgba(65, 121, 127, 0.5);
}

.suggestionRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.suggestionItem,
.suggestionItemActive {
  @extend .suggestionRow;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  margin-left: 0 !important;
  padding: 0.5rem 1rem;

  &:hover {
    background-color: lighten($grey-very-light, 5%);
  }
}

.suggestionItemActive {
  background-color: lighten($grey-very-light, 5%);
}

.suggestionText {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.suggestionMainText {
  font-weight: 600;
}

.suggestionSecondaryText {
  color: $grey-dark;
  font-size: 0.75rem;
  font-weight: 300;
  margin-top: 0.25rem;
}

.highlightedQuery {
  font-weight: 600;
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
}

.poweredByGoogle {
  align-self: flex-end;
  margin: 0.5rem;
  width: 6rem;
}

.overview {
  display: none;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);

  &.focused {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  span {
    color: $light-turquoise;
    font-weight: 600;
    margin-top: 25px;
    opacity: 0.6;
  }
}

.footer {
  border-bottom: 1px solid $inputBorder;
  top: 0;
  display: none;
  right: 0;
  padding: 1rem;
  position: relative;
  justify-content: flex-end;
  width: 100%;

  &.focused {
    display: flex;
  }
}

.btn {
  background-color: transparent;
  border: none;
  color: $grey;
  font-size: 0.875rem;
  padding: 0;

  &:disabled {
    opacity: 0.5;
  }
}

.mobileSearchIcon {
  padding: 5px;
  z-index: 1;
  height: 2rem;
  width: auto;
  cursor: pointer;

  // Positioning mobile search icon
  @include mediaQuery(max, $tablet + 1) {
    left: -3rem;
  }
  @include mediaQuery(max, $mobile) {
    left: 1rem;
  }
}

.mobileMenuActive {
  display: none;
}

.recentSearches {
  @extend .suggestions;

  &.hidden {
    display: none;
  }

  margin-top: 5px;
  padding-top: 15px;
  padding-bottom: 10px;
  width: 100%;

  @include mediaQuery(max, $mobile) {
    margin-top: -0.5rem;
  }

  a {
    @extend .suggestionRow;
  }

  .suggestionIcon {
    margin-top: 1px;
  }

  .suggestionMainText,
  .suggestionSecondaryText {
    color: $bodyText;
  }

  .suggestionMainText {
    font-size: 0.875rem;
  }

  .suggestionSecondaryText {
    font-weight: 600;
    color: $grey-dark;
  }

  .suggestionItem {
    &:hover {
      background-color: $suggestionHilight;
    }

    &:first-of-type {
      margin-top: calc(15px - 0.5rem);
    }
  }
}

.recentSearchLabel {
  text-transform: uppercase;
  color: $saldova;
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  font-weight: 800;
  margin-left: 0;
  padding: 0.5rem 1rem;
}
